import { Injectable, inject } from '@angular/core';
import { tap, finalize, Subject, lastValueFrom } from 'rxjs';

import { SecurityStorage } from './storages/security.storage';
import { SecuritySessionStorage } from './storages/security.session-storage';
import { AuthStore } from './stores/auth.store';
import { OptionalType } from '../models/types/optional.type';
import { AuthenticatedUserModel } from '../../app/models/authenticated-user.model';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    get isLoading(): boolean {
        return this.isLoadingLocal;
    }

    readonly userInfoSubject = new Subject<AuthenticatedUserModel>();

    private readonly securityStorage = inject(SecurityStorage);
    private readonly securitySession = inject(SecuritySessionStorage);
    private readonly authStore = inject(AuthStore);

    private isLoadingLocal = false;

    get defaultRoundTo(): number {
        return this.securitySession.getUserInfo()?.defaultRoundTo ?? 0.05;
    }
    get articlePriceDecimalDigits(): number {
        return this.securitySession.getUserInfo()?.articlePriceDecimalDigits ?? 2;
    }

    initUserData(): void {
        const token = this.securityStorage.getToken();
        const userInfo = this.securitySession.getUserInfo();

        if (!!token && !userInfo) {
            this.isLoadingLocal = true;
            this.authStore.getUser().pipe(
                tap(resp => {
                    if (!!resp.value) {
                        this.securitySession.saveUserInfo(resp.value);
                        this.userInfoSubject.next(resp.value);
                        this.userInfoSubject.complete();
                    }
                }),
                finalize(() => this.isLoadingLocal = false)
            ).subscribe();
        }
    }

    getUserInfo(): OptionalType<AuthenticatedUserModel> {
        return this.securitySession.getUserInfo();
    }

    async getUserInfoAsync(): Promise<OptionalType<AuthenticatedUserModel>> {
        const token = this.securityStorage.getToken();
        const userInfo = this.securitySession.getUserInfo();

        if (!!token && !!userInfo) {
            return userInfo;
        }

        if (!!token && !userInfo && this.isLoadingLocal) {
            return lastValueFrom(this.userInfoSubject);
        }

        return this.securitySession.getUserInfo();
    }
}
