<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-input labelKey="Settings.Edit.Security.Password.ComplexityLength"
        [control]="formGroup.controls.passwordComplexityLength"></arc-input>
    <arc-input labelKey="Settings.Edit.Security.Password.AuthenticationExpiryMinutes"
        [control]="formGroup.controls.authenticationExpiryMinutes"></arc-input>

    <span class="mt-4 text-xl leading-tight">{{'Settings.Edit.Security.Password.MinimumRequirements' | transloco}}</span>
    <arc-checkbox labelKey="Settings.Edit.Security.Password.ComplexityLower" [control]="formGroup.controls.passwordComplexityLower">
    </arc-checkbox>
    <arc-checkbox labelKey="Settings.Edit.Security.Password.ComplexityUpper" [control]="formGroup.controls.passwordComplexityUpper">
    </arc-checkbox>
    <arc-checkbox labelKey="Settings.Edit.Security.Password.ComplexityNumber" [control]="formGroup.controls.passwordComplexityNumber">
    </arc-checkbox>
    <arc-checkbox labelKey="Settings.Edit.Security.Password.ComplexitySpecial" [control]="formGroup.controls.passwordComplexitySpecial">
    </arc-checkbox>
</form>
