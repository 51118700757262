import { Injectable, inject } from '@angular/core';

import { SidebarService } from './sidebar.service';
import { HelpComponent } from '../../components/help/help.component';
import { TranslationService } from './translation.service';
import { RightSidebarConfigModel } from '../models/right-sidebar-config.model';
import { SidebarHeaderService } from './sidebar-header.service';
import { TicketsComponent } from '../../app/components/tickets/tickets.component';

@Injectable({
    providedIn: 'root'
})
export class HelpService {
    currentlyOpenedTicketId?: number;

    private readonly sidebarService = inject(SidebarService);
    private readonly translationService = inject(TranslationService);
    private readonly headerService = inject(SidebarHeaderService);

    openHelp(helpArticleId?: string): void {
        const config = new RightSidebarConfigModel({
            component: HelpComponent,
            data: helpArticleId,
            width: 'full',
            maxWidth: '800px',
            shouldCloseOnBackdropClick: true
        });

        this.sidebarService.openRight<undefined>(config);
        this.headerService.hasCloseButton.set(true);
        this.translationService.getTextObservable('Help.ComponentTitle').subscribe(this.headerService.title.set);
    }

    openTickets(): void {
        const config = new RightSidebarConfigModel({
            component: TicketsComponent,
            width: 'full',
            maxWidth: '800px',
            shouldCloseOnBackdropClick: true
        });

        this.sidebarService.openRight<undefined>(config);
        this.headerService.hasCloseButton.set(true);
        this.headerService.title.set(this.translationService.getText('Tickets.ComponentTitle'));
    }
}
