import { Component, inject, input } from '@angular/core';
import { Router } from '@angular/router';

import { StaticTableConfigModel } from '../../../list-views/static-table/models/static-table-config.model';
import { SimpleTransactionModel } from '../../../../app/models/simple-transaction.model';
import { DateService } from '../../../../core/services/date.service';
import { StackedColumnModel } from '../../../dynamic-table/models/column-types/stacked-column.model';
import { ColoredStackedColumnModel } from '../../../dynamic-table/models/column-types/colored-stacked-column.model';
import { DictionaryType } from '../../../../core/models/types/dictionary.type';
import { CurrencyColumnModel } from '../../../dynamic-table/models/column-types/currency-column.model';
import { NumberPipe } from '../../../../core/pipes/number.pipe';
import { TranslationService } from '../../../../core/services/translation.service';

@Component({
    selector: 'arc-transactions-list-widget',
    templateUrl: './transactions-list-widget.component.html',
    styleUrls: ['./transactions-list-widget.component.scss'],
    providers: [NumberPipe]
})
export class TransactionsListWidgetComponent {
    items = input.required<SimpleTransactionModel[]>();
    queryParams = input<DictionaryType<string>>({});
    isLoading = input(false);
    tableConfig: StaticTableConfigModel = {
        defaultPageSize: 4,
        availableColumns: [
            new StackedColumnModel({
                columnTitleKey: 'Transactions.List.Transaction',
                propertyName: 'title',
                propertyName2: 'number',
                widthPixels: 105,
                isCompact: true
            }),
            new StackedColumnModel({
                columnTitleKey: 'Transactions.List.Date',
                propertyName: 'saleTime',
                propertyName2: 'saleDay',
                widthPixels: 100,
                isCompact: true,
                customFormatter: (record, value, propertyName) => {
                    if (propertyName === 'saleDay') {
                        return record.displaySaleDay ? this.dateService.format(value) : '';
                    } else {
                        return this.dateService.format(
                            value, this.translationService.current.code === 'en' ? 'dd/MM/yy HH:mm' : 'short'
                        ).replace(',', '');
                    }
                }
            }),
            new ColoredStackedColumnModel({
                columnTitleKey: 'Transactions.List.PointOfSale',
                propertyName: 'posName',
                propertyName2: 'regionBranchDescription',
                color: 'posColor',
                widthPixels: 110,
                isCompact: true
            }),
            new CurrencyColumnModel({
                columnTitleKey: 'TransactionPayments.List.Amount',
                propertyName: 'amount',
                widthPixels: 80,
                isCompact: true
            })
        ]
    };

    private readonly dateService = inject(DateService);
    private readonly translationService = inject(TranslationService);
    private readonly router = inject(Router);

    goToTransactionsPage(): void {
        this.router.navigate(['transactions'], { queryParams: this.queryParams() }).then();
    }
}
