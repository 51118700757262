import { Pipe, PipeTransform, inject } from '@angular/core';

import { TranslationService } from '../services/translation.service';
import { UserService } from '../services/user.service';
import { Tools } from '../utils/tools';

@Pipe({ name: 'arcCurrency' })
export class CurrencyPipe implements PipeTransform {
    private static currencyCode = '';

    private readonly userService = inject(UserService);
    private readonly translationService = inject(TranslationService);

    transform(value: any, localize = true, shouldRoundByUserSettings = false): string {
        if (!CurrencyPipe.currencyCode) {
            CurrencyPipe.currencyCode = this.userService.getUserInfo()?.currencyIsoCode ?? '';
        }

        let numberVal = Number(value);

        if (Number.isNaN(numberVal)) {
            return value;
        }

        if (shouldRoundByUserSettings) {
            numberVal = Tools.Utils.roundTo(numberVal, this.userService.defaultRoundTo);
        }

        // Only display more than 2 decimal digits if the value is different than all zeroes (eg: 10.0000 would display 10.00).
        const decimals = numberVal % 1;
        const fractionDigits = decimals > 0 ? this.userService.articlePriceDecimalDigits : 2;

        if (localize) {
            value = numberVal.toLocaleString(
                this.translationService.current.culture, { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }
            );
        } else {
            value = numberVal.toFixed(fractionDigits);
        }

        return `${CurrencyPipe.currencyCode} ` + value;
    }
}
