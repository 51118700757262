import { Directive, inject } from '@angular/core';

import { PageListViewComponent } from '../list-views/page-list-view/page-list-view.component';
import { ActionButtonModel } from '../../core/models/action-button.model';
import { ReportListModel } from '../../app/models/responses/report-list.model';
import { Tools } from '../../core/utils/tools/index';
import { SearchRequestService } from '../../core/services/search-request.service';
import { ReportStore } from './report-store';
import { Identifyable } from '../../core/abstractions/identifyable';
import { PermissionsEnum } from '../../app/models/enums/permissions.enum';
import { PermissionTypeEnum } from '../../core/models/enums/permission-type.enum';
import { PermissionService } from '../../core/services/permission.service';
import { AuthPermissionModel } from '../../app/models/auth-permission.model';
import { ModuleIdentificationsEnum, ModuleIdentificationsEnumExtensions } from '../../app/models/enums/module-identifications.enum';

@Directive({})
export abstract class BaseReportPage<TList extends Identifyable> extends PageListViewComponent<TList> {
    protected _customActionButtons: ActionButtonModel[] = [];
    // eslint-disable-next-line @typescript-eslint/naming-convention
    protected _showTemplates = false;

    protected readonly _searchRequestService = inject(SearchRequestService);
    private readonly permissionService = inject(PermissionService);

    private readonly isPos: boolean;
    private readonly store: ReportStore;

    protected constructor(store: ReportStore, isPos = false, ...extraButtons: ActionButtonModel[]) {
        super('Reporting');

        this.isPos = isPos;
        this.store = store;
        this._customActionButtons = [
            new ActionButtonModel({
                clickFn: (btn: ActionButtonModel) => {
                    this.toggleTemplates();

                    btn.icon = this._showTemplates ? 'edit_off' : 'edit';
                    btn.color = this._showTemplates ? 'accent' : 'primary';

                    this._actionButtonsService.updateBasicConfig(btn);
                },
                color: 'primary',
                icon: 'edit',
                min: 0,
                max: 0,
                isEnabled: true,
                requiredPermission: PermissionsEnum.Reporting,
                requiredPermissionType: PermissionTypeEnum.Update
            }),
            ...extraButtons,
            new ActionButtonModel({
                text: 'Reports.OpenInEditor',
                icon: 'design_services',
                min: 1,
                max: 1,
                clickFn: (btn, data) => {
                    data = Array.isArray(data) ? data : [data];
                    this.handleOpenInEditorButtonClick(data);
                },
                isHidden: (btn, data) => !!data && !(this._showTemplates && !data.isTemplate),
                requiredPermission: PermissionsEnum.Reporting,
                requiredPermissionType: PermissionTypeEnum.Update
            }),
            new ActionButtonModel({
                text: 'General.Actions.Update',
                icon: 'autorenew',
                min: 1,
                max: 1,
                order: 1,
                clickFn: (btn, data) => {
                    data = Array.isArray(data) ? data : [data];
                    this.handleUpdateButtonClick(data);
                },
                isHidden: (btn, data) => !!data && !data.isUpdateAvailable,
                requiredPermission: PermissionsEnum.Reporting,
                requiredPermissionType: PermissionTypeEnum.Update
            }),
            new ActionButtonModel({
                text: 'General.Actions.Install',
                icon: 'install_desktop',
                min: 1,
                max: 1,
                clickFn: (btn, data) => {
                    data = Array.isArray(data) ? data : [data];
                    const item = data[0];

                    if (item.hasRequiredModule || isPos) { // PosReports and PosReportTemplates do not have RequiredModules
                        this.handleInstallButtonClick(data);
                    } else {
                        const moduleName = ModuleIdentificationsEnumExtensions.getKey(item.requiredModuleId) as ModuleIdentificationsEnum;

                        const permission: AuthPermissionModel = {
                            isLicensed: false,
                            requiredModuleId: item.requiredModuleId,
                            permissionId: moduleName,
                            permit: false,
                            permitCreate: false,
                            permitDelete: false,
                            permitUpdate: false
                        };

                        this.permissionService.showUpgradeModal(permission);
                    }
                },
                isHidden: (btn, data) => !!data && !(this._showTemplates && data.isTemplate && !data.isInstalled),
                requiredPermission: PermissionsEnum.Reporting,
                requiredPermissionType: PermissionTypeEnum.Update
            }),
            new ActionButtonModel({
                text: 'General.Actions.Duplicate',
                icon: 'file_copy',
                min: 1,
                max: 1,
                order: 25,
                clickFn: (btn, data) => {
                    data = Array.isArray(data) ? data : [data];
                    this.handleDuplicateButtonClick(data);
                },
                isHidden: (btn, data) => !!data && !(this._showTemplates && data.isInstalled),
                requiredPermission: PermissionsEnum.Reporting,
                requiredPermissionType: PermissionTypeEnum.Create
            })
        ];

        //  roleId 1 is the SuperAdmin
        if (this._userService.getUserInfo()?.roleId === 1) {
            this._customActionButtons.push(
                new ActionButtonModel({
                    text: 'Reports.DownloadReportFile',
                    icon: 'download',
                    min: 1,
                    max: 1,
                    order: 110,
                    clickFn: (btn, data) => {
                        data = Array.isArray(data) ? data : [data];
                        this.handleDownloadButtonClick(data);
                    },
                    isHidden: (btn, data) => !!data && !(this._showTemplates && data.isInstalled)
                })
            );
        }
    }

    toggleTemplates(): void {
        this._showTemplates = !this._showTemplates;
        this.store.showTemplates = this._showTemplates;

        this._searchRequestService.forceReload();
    }

    handleOpenInEditorButtonClick(items: TList[]): void {
        const id = items[0].id;

        if (this.isPos) {
            this._router.navigate(['pos-reports', 'edit', `p_${id}`]).then();
            return;
        }

        this._router.navigate(['reports', 'edit', id]).then();
    }

    handleInstallButtonClick(data: ReportListModel[]): void {
        this.store.installReport(data[0].templateKey!).subscribe(response => {
            if (response.value) {
                this._searchRequestService.forceReload();
            }
        });
    }

    handleDuplicateButtonClick(data: ReportListModel[]): void {
        this.store.duplicateReport(data[0].id).subscribe(response => {
            if (response.value) {
                this._searchRequestService.forceReload();
            }
        });
    }

    handleUpdateButtonClick(data: ReportListModel[]): void {
        return this.handleInstallButtonClick(data);
    }

    handleDownloadButtonClick(data: ReportListModel[]): void {
        this.store.downloadReport(data[0].id).subscribe(response => {
            const blob = new Blob(['', response.body], { type: response.body.type });
            Tools.Utils.saveFile(blob, Tools.Utils.getFileNameFromResponse(response));
        });
    }
}
