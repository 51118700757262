@if (!!selectedArticle()) {
    <div class="border border-solid border-on-app-light rounded-xl p-5 mt-8 mb-8">
        <h2 class="text-3xl font-bold text-accent">{{selectedArticle().title}}</h2>
        <div class="arc-markdown" markdown [innerHTML]="selectedArticle().text"></div>
    </div>

    @if(showRelatedArticles() && selectedArticle().relatedArticles.length > 0){
        <h2 class="text-accent font-bold">{{'Help.RelatedArticles' | transloco}}</h2>
        <div class="flex flex-col gap-4 mt-8 mb-10">
            @for(article of selectedArticle().relatedArticles; track article.id){
                <arc-article-list-item [article]="article"></arc-article-list-item>
            }
        </div>
    }
} @else {
    <div class="border border-solid border-on-app-light rounded-xl p-5 mt-8 mb-8">
        <h2 class="text-3xl font-bold text-accent">{{'Help.ArticleNotAvailable' | transloco}}</h2>
    </div>
}
