import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { of, Observable, tap, map, switchMap } from 'rxjs';

import { ArticleModel } from '../../../models/article.model';
import { ArticleListModel } from '../../../models/responses/article-list.model';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { CurrencyColumnModel } from '../../../../components/dynamic-table/models/column-types/currency-column.model';
import { MainArticlesStore } from '../../../services/stores/main-articles.store';
import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { ArticleEditTextsComponent } from './article-edit-items/article-edit-texts/article-edit-texts.component';
import { ArticleEditBaseDataComponent } from './article-edit-items/article-edit-base-data/article-edit-base-data.component';
import { ArticlePicturesDetailWidget } from './article-detail-widgets/article-pictures/article-pictures.detail-widget';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { ButtonColumnModel } from '../../../../components/dynamic-table/models/column-types/button-column.model';
import { ArticleSalesNumbersDetailWidget } from './article-detail-widgets/article-sales-numbers/article-sales-numbers.detail-widget';
import { ArticleArticleStockDataDetailWidget } from './article-detail-widgets/article-stock-data/article-article-stock-data.detail-widget';
import { ArticleDescriptionDetailWidget } from './article-detail-widgets/article-description/article-description.detail-widget';
import { PermissionsEnum } from '../../../models/enums/permissions.enum';
import { ArticleEditPropertiesComponent } from './article-edit-items/article-edit-properties/article-edit-properties.component';
import { ArticleEditModel } from '../../../models/requests/article-edit.model';
import { ArticleEditIdentificationsComponent } from './article-edit-items/article-edit-identifications/article-edit-identifications.component';
import { ArticleEditPricesComponent } from './article-edit-items/article-edit-prices/article-edit-prices.component';
import { ArticleEditSuppliersComponent } from './article-edit-items/article-edit-suppliers/article-edit-suppliers.component';
import { ArticleEditSetArticlesComponent } from './article-edit-items/article-edit-set-articles/article-edit-set-articles.component';
import { ArticleDetailsDetailWidget } from './article-detail-widgets/article-details/article-details.detail-widget';
import { ArticleEditImagesComponent } from './article-edit-items/article-edit-images/article-edit-images.component';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';
import { ArticleEditVariantsComponent } from './article-edit-items/article-edit-variants/article-edit-variants.component';
import { ArticleCreateDialogComponent } from './article-create-dialog/article-create-dialog.component';
import { PermissionService } from '../../../../core/services/permission.service';
import { SharedDataService } from '../../../../core/services/shared-data.service';
import { SidebarService } from '../../../../core/services/sidebar.service';
import { ModuleIdentificationsEnum } from '../../../models/enums/module-identifications.enum';
import { CustomColumnModel } from '../../../../components/dynamic-table/models/column-types/custom-column.model';
import { ArticleOnSaleColumnComponent } from './article-on-sale-column/article-on-sale-column.component';
import { OptionalType } from '../../../../core/models/types/optional.type';
import { ArticleVariantsDetailWidget } from './article-detail-widgets/article-variants/article-variants.detail-widget';

@Component({
    selector: 'arc-articles',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent extends PageListViewComponent<ArticleListModel, ArticleModel, ArticleEditModel> {
    private readonly mainArticlesStore = inject(MainArticlesStore);
    private readonly matDialog = inject(MatDialog);
    private readonly permissionsService = inject(PermissionService);
    private readonly sharedDataService = inject(SharedDataService);
    private readonly sidebarService = inject(SidebarService);

    constructor() {
        super('Articles');
        this.config = new TableListViewConfigModel<ArticleListModel, ArticleModel, ArticleEditModel>({
            entityName: 'Articles',
            availableColumns: {
                title: new StackedColumnModel({
                    columnTitleKey: 'Articles.List.Article',
                    propertyName: 'title',
                    propertyName2: 'articleNumber',
                    widthPixels: 236
                }),
                price: new CurrencyColumnModel({
                    columnTitleKey: 'Articles.List.Price',
                    propertyName: 'price',
                    widthPixels: 126
                }),
                unit: new StringColumnModel({ columnTitleKey: 'Articles.List.Unit', propertyName: 'unit', widthPixels: 120 }),
                fashion: new ButtonColumnModel({
                    columnTitleKey: 'Articles.List.Variants',
                    propertyName: 'variantsCount',
                    suffix: () => 'Articles.List.Variants',
                    icon: () => 'launch',
                    bgColor: 'var(--btn-dark)',
                    fontColor: 'var(--on-primary)',
                    onClickFunction: (item: ArticleListModel) => this.navigateToVariants(item.id),
                    hideButton: (item: ArticleListModel) => this.hideButton(item),
                    widthPixels: 200
                }),
                groups: new StackedColumnModel({
                    columnTitleKey: 'Articles.List.Group',
                    propertyName: 'articleGroup',
                    propertyName2: 'mainAndTradeGroup',
                    widthPixels: 236
                }),
                mainSupplier: new StringColumnModel({
                    columnTitleKey: 'Articles.List.MainSupplier',
                    propertyName: 'mainSupplier',
                    widthPixels: 236,
                    requiredPermission: PermissionsEnum.ArticleSuppliers
                }),
                buyingPrice: new CurrencyColumnModel({
                    columnTitleKey: 'Articles.List.BuyingPrice',
                    propertyName: 'buyingPrice',
                    widthPixels: 126,
                    requiredPermission: PermissionsEnum.BuyingPrice
                }),
                totalStock: new StringColumnModel({
                    columnTitleKey: 'Articles.List.TotalStock',
                    propertyName: 'totalStock',
                    widthPixels: 126,
                    requiredPermission: PermissionsEnum.ArticleStocks
                }),
                isOnSale: new CustomColumnModel({
                    columnTitleKey: 'Articles.List.IsOnSale',
                    shouldHideColumnTitle: true,
                    propertyName: 'isOnSale',
                    widthPixels: 150,
                    customComponent: ArticleOnSaleColumnComponent
                })
            },
            defaultColumnOrder: ['title', 'price', 'unit', 'groups', 'fashion', 'mainSupplier', 'buyingPrice', 'totalStock', 'isOnSale'],
            availableDetailWidgets: {
                pictures: new TableListDetailWidgetModel({
                    name: 'Articles.Detail.Pictures.ComponentTitle',
                    component: ArticlePicturesDetailWidget
                }),
                turnover: new TableListDetailWidgetModel({
                    name: 'Articles.Detail.Turnover.ComponentTitle',
                    component: ArticleSalesNumbersDetailWidget,
                    requiredPermission: PermissionsEnum.HomeStatistics
                }),
                stock: new TableListDetailWidgetModel({
                    name: 'Articles.Detail.Stock.ComponentTitle',
                    component: ArticleArticleStockDataDetailWidget,
                    requiredPermission: PermissionsEnum.ArticleStocks
                }),
                description: new TableListDetailWidgetModel({
                    name: 'Articles.Detail.Description.ComponentTitle',
                    component: ArticleDescriptionDetailWidget
                }),
                details: new TableListDetailWidgetModel({
                    name: 'Articles.Detail.Details.ComponentTitle',
                    component: ArticleDetailsDetailWidget
                }),
                variants: new TableListDetailWidgetModel({
                    name: 'Articles.Detail.Variants.ComponentTitle',
                    component: ArticleVariantsDetailWidget
                })
            },
            defaultDetailWidgetOrder: ['pictures', 'turnover', 'stock', 'description', 'details', 'variants'],
            store: this.mainArticlesStore,
            editSidebarConfig: {
                editComponents: [
                    { titleKey: 'Articles.Edit.BaseData.ComponentTitle', component: ArticleEditBaseDataComponent },
                    { titleKey: 'Articles.Edit.Texts.ComponentTitle', component: ArticleEditTextsComponent },
                    { titleKey: 'Articles.Edit.Properties.ComponentTitle', component: ArticleEditPropertiesComponent },
                    {
                        titleKey: 'Articles.Edit.Identifications.ComponentTitle',
                        component: ArticleEditIdentificationsComponent,
                        requiredPermission: PermissionsEnum.ArticleIdentifications,
                        shouldHideSignalName: 'isMainArticle'
                    },
                    {
                        titleKey: 'Articles.Edit.PriceLevels.ComponentTitle',
                        component: ArticleEditPricesComponent,
                        requiredPermission: PermissionsEnum.PriceLevels
                    },
                    {
                        titleKey: 'Articles.Edit.Suppliers.ComponentTitle',
                        component: ArticleEditSuppliersComponent,
                        requiredPermission: PermissionsEnum.ArticleSuppliers
                    },
                    {
                        titleKey: 'Articles.Edit.SetArticles.ComponentTitle',
                        component: ArticleEditSetArticlesComponent,
                        requiredPermission: PermissionsEnum.ArticleSets,
                        shouldHideSignalName: 'isMainArticle'
                    },
                    { titleKey: 'Articles.Edit.Images.ComponentTitle', component: ArticleEditImagesComponent },
                    {
                        titleKey: 'Articles.Edit.Variants.ComponentTitle',
                        component: ArticleEditVariantsComponent,
                        requiredPermission: PermissionsEnum.Variants,
                        shouldHideSignalName: 'isNotMainArticle'
                    }
                ],
                headerSubtitle: currentItem => of(currentItem.title)
            },
            buttonsVisibility: new ButtonsVisibilityModel({ hasImport: true, hasDuplicate: true }),
            propertiesExcludedFromDuplication: ['articleNumber', 'identifications'],
            transformRecordForDuplication: this.transformRecordForDuplication.bind(this),
            handleCreateButtonClick: this.handleCreate.bind(this),
            handleEditButtonClick: this.handleEdit.bind(this),
            doBeforeOpenEditSidebarForDuplication: this.doAfterDuplicateContentLoaded.bind(this)
        });
    }

    private navigateToVariants(id: number): void {
        this._router.navigate(['variations'], { queryParams: { MainArticleId: [id] } }).then();
    }

    private hideButton(item: ArticleListModel): boolean {
        return item.variantsCount <= 0;
    }

    private handleCreate(): Observable<boolean> {
        const hasFashion = this.permissionsService.hasModuleLicensed(ModuleIdentificationsEnum.Fashion);

        if (!hasFashion) {
            return this.openSidebar(undefined, false);
        }

        return this.matDialog
            .open(ArticleCreateDialogComponent, {
                maxHeight: '98svh',
                maxWidth: '98vw'
            })
            .afterClosed()
            .pipe(
                switchMap(isFashionArticle => isFashionArticle === undefined ? of(false) : this.openSidebar(undefined, isFashionArticle))
            );
    }

    private handleEdit(item: ArticleListModel): Observable<boolean> {
        return this.openSidebar(item.id, item.variantsCount > 0);
    }

    private transformRecordForDuplication(item: ArticleModel): Observable<ArticleModel> {
        item.variants?.forEach(v => {
            v.id = 0;
            v.identifications = [];

            v.articleImages?.forEach(ai => ai.id = 0);
            v.articleSuppliers?.forEach(s => {
                s.id = 0;
                s.articleId = 0;
            });
        });
        item.articleSuppliers?.forEach(s => {
            s.id = 0;
            s.articleId = 0;
        });
        item.setArticles?.forEach(sa => sa.id = 0);
        item.articleImages?.forEach(ai => ai.id = 0);

        return of(item);
    }

    private doAfterDuplicateContentLoaded(item: ArticleModel): void {
        this.doAfterSidebarOpened(item.variants.length > 0);
    }

    private doAfterSidebarOpened(isMainArticle: boolean): void {
        setTimeout(() => {
            this.sharedDataService.setOrUpdateSignal<boolean>('isNotMainArticle', !isMainArticle);
            this.sharedDataService.setOrUpdateSignal<boolean>('isMainArticle', isMainArticle);
        }, 0);
    }

    private openSidebar(id: OptionalType<number>, isMainArticle: boolean): Observable<boolean> {
        const rightOpenSub = this.sidebarService.rightOpenSubject.subscribe(isOpen => {
            if (isOpen) {
                this.doAfterSidebarOpened(isMainArticle);
            }
        });

        return this.listView.openEditSidebar(id).pipe(
            tap(() => rightOpenSub.unsubscribe()),
            map(shouldReload => !!shouldReload)
        );
    }
}
