export class WelcomeBarModel {
    userFullName!: string;
    licenseName!: string;
    welcomeBarText!: string;

    constructor(init?: Partial<WelcomeBarModel>) {
        if (!!init) {
            Object.assign(this, init);
        }
    }
}
