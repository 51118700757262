import { Injectable, inject } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { of, map, switchMap, forkJoin, tap, filter, Observable } from 'rxjs';

import { OrderStatusEnum } from '../../../../../models/enums/order-status.enum';
import { EditableTableConfigModel } from '../../../../../../components/form/editable-table/models/editable-table-config.model';
import { OrderArticleModel } from '../../../../../models/order-article.model';
import { CurrencyPipe } from '../../../../../../core/pipes/currency.pipe';
import { NumberPipe } from '../../../../../../core/pipes/number.pipe';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { OrdersStore } from '../../../../../services/stores/orders.store';
import { ArticlesStore } from '../../../../../services/stores/articles.store';
import { SupplierArticlesStore } from '../../../../../services/stores/supplier-articles.store';
import { RequestService } from '../../../../../../core/services/request.service';
import { ArticleStocksStore } from '../../../../../services/stores/article-stocks.store';
import { DataSelectionDialogComponent } from '../../../../../../components/dialogs/data-selection-dialog/data-selection-dialog.component';
import { articleSupplierSelectionTableConfig } from '../../../../../../components/dialogs/data-selection-dialog/data-selection-table-configs/article-supplier-selection-table.config';
import { DataSelectionDialogCustomDataModel } from '../../../../../../components/dialogs/data-selection-dialog/models/data-selection-dialog-custom-data.model';
import { ButtonColumnModel } from '../../../../../../components/dynamic-table/models/column-types/button-column.model';
import { NumberColumnModel } from '../../../../../../components/dynamic-table/models/column-types/number-column.model';
import { StackedColumnModel } from '../../../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { StringColumnModel } from '../../../../../../components/dynamic-table/models/column-types/string-column.model';
import { EditableTableButtonModel } from '../../../../../../components/form/editable-table/models/editable-table-button.model';
import { TextAlignmentEnum } from '../../../../../../core/models/enums/text-alignment.enum';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { ArcFormControl, ArcFormControlInterface } from '../../../../../../core/utils/arc-form-control';
import { CustomValidators } from '../../../../../../core/utils/custom-validators';
import { ArticleSupplierListModel } from '../../../../../models/responses/article-supplier-list.model';
import { OrderArticleEditDialogComponent } from './order-article-edit-dialog/order-article-edit-dialog.component';
import { OrderModel } from '../../../../../models/order.model';
import { CustomColumnModel } from '../../../../../../components/dynamic-table/models/column-types/custom-column.model';
import { OrderArticleStatusColumnComponent } from './order-article-status-column/order-article-status-column.component';
import { GeneralPromptDialogComponent } from '../../../../../../components/dialogs/general-prompt-dialog/general-prompt-dialog.component';
import { GeneralPromptDialogDataModel } from '../../../../../../components/dialogs/general-prompt-dialog/models/general-prompt-dialog-data.model';

@Injectable()
export class OrderArticlesTableConfigService {
    private readonly ordersStore = inject(OrdersStore);
    private readonly articlesStore = inject(ArticlesStore);
    private readonly requestService = inject(RequestService);
    private readonly articleStocksStore = inject(ArticleStocksStore);
    private readonly currencyPipe = inject(CurrencyPipe);
    private readonly numberPipe = inject(NumberPipe);
    private readonly translationService = inject(TranslationService);
    private readonly matDialog = inject(MatDialog);

    private columns = {
        articleTitle: new StackedColumnModel({
            propertyName: 'articleTitle',
            propertyName2: 'articleArticleNumber',
            maxWidthPixels: 300,
            customFormatter: (record, value, propertyName) => {
                if (propertyName === 'articleTitle') {
                    return value;
                } else {
                    return `${value} / ${record.orderNumber}`;
                }
            },
            columnTitleKey: 'Orders.Edit.Articles.Article'
        }),
        orderQuantity: (isEditable: boolean) =>
            new NumberColumnModel({
                propertyName: 'orderQuantity',
                isEditable,
                widthPixels: 180,
                unit: record => `x ${record.orderUnitQuantity} ${record.articleUnitTitle}`,
                columnTitleKey: 'Orders.Edit.Articles.OrderQuantity'
            }),
        deliveredQuantity: (isEditable: boolean) =>
            new NumberColumnModel({
                propertyName: 'deliveredQuantity',
                isEditable,
                widthPixels: 180,
                unit: record => `x ${record.deliveredUnitQuantity} ${record.articleUnitTitle}`,
                columnTitleKey: 'Orders.Edit.Articles.DeliveredQuantity'
            }),
        x: new StringColumnModel({
            propertyName: 'x',
            textAlignment: TextAlignmentEnum.Center,
            widthPixels: 30,
            customFormatter: () => 'x',
            shouldHideColumnTitle: true
        }),
        buyingPrice: new StringColumnModel({
            propertyName: 'orderBuyingPrice',
            suffixPropertyName: 'orderBuyingPriceExclusive',
            customFormatter: (record, value, propertyName) => {
                if (propertyName === 'orderBuyingPrice') {
                    return this.currencyPipe.transform(value);
                } else {
                    return this.translationService.getText(
                        value ? 'Orders.Edit.Articles.BuyingPriceExclusive' : 'Orders.Edit.Articles.BuyingPriceInclusive'
                    );
                }
            },
            columnTitleKey: 'Orders.Edit.Articles.BuyingPrice'
        }),
        equals: new StringColumnModel({
            propertyName: '=',
            textAlignment: TextAlignmentEnum.Center,
            widthPixels: 30,
            customFormatter: () => '=',
            shouldHideColumnTitle: true
        }),
        total: new StackedColumnModel({
            propertyName: 'total',
            propertyName2: 'totalQuantity',
            customFormatter: (record, value, propertyName) => {
                if (propertyName === 'total') {
                    const total = record.orderQuantity * record.orderUnitQuantity * record.orderBuyingPrice;
                    return this.currencyPipe.transform(Number.isNaN(total) ? 0 : total, undefined, true);
                }

                // total quantity
                const totalQuantity = record.orderQuantity * record.orderUnitQuantity;

                return this.numberPipe.transform(
                    Number.isNaN(totalQuantity) ? '0' : totalQuantity.toString(),
                    undefined,
                    undefined,
                    ` ${record.articleUnitTitle ?? ''}`
                );
            },
            columnTitleKey: 'Orders.Edit.Articles.Total'
        }),
        totalDelivered: new StackedColumnModel({
            propertyName: 'totalDelivered',
            propertyName2: 'totalDeliveredQuantity',
            customFormatter: (record, value, propertyName) => {
                if (propertyName === 'totalDelivered') {
                    const total = record.deliveredQuantity * record.deliveredUnitQuantity * record.orderBuyingPrice;
                    return this.currencyPipe.transform(Number.isNaN(total) ? 0 : total);
                }
                // total quantity
                const totalDeliveredQuantity = record.deliveredQuantity * record.deliveredUnitQuantity;
                return this.numberPipe.transform(
                    Number.isNaN(totalDeliveredQuantity) ? '0' : totalDeliveredQuantity.toString(),
                    undefined,
                    undefined,
                    ` ${record.articleUnitTitle ?? ''}`
                );
            },
            columnTitleKey: 'Orders.Edit.Articles.Total'
        }),
        deliveredStatus: new CustomColumnModel<OrderArticleModel>({
            propertyName: 'deliveredStatus',
            shouldHideColumnTitle: true,
            customComponent: OrderArticleStatusColumnComponent
        }),
        editDetails: (orderStatus: OrderStatusEnum) =>
            new ButtonColumnModel<OrderArticleModel>({
                propertyName: 'details',
                shouldHideColumnTitle: true,
                icon: () => 'edit',
                onClickFunction: (item, formGroup) => {
                    this.matDialog
                        .open(OrderArticleEditDialogComponent, {
                            data: {
                                item,
                                orderStatus
                            }
                        })
                        .afterClosed()
                        .subscribe((data?: OrderArticleModel) => {
                            if (!!data) {
                                formGroup?.patchValue(data);
                            }
                        });
                }
            })
    };

    getTableConfig(
        order: OrderModel,
        formGroup: FormGroup<{
            orderArticles: ArcFormControlInterface<OrderArticleModel[]>;
        }>
    ): EditableTableConfigModel<OrderArticleModel, string> {
        switch (order.status) {
            case OrderStatusEnum.Suggestion:
            case OrderStatusEnum.Declined:
            case OrderStatusEnum.Draft:
                return new EditableTableConfigModel<OrderArticleModel, string>({
                    formGroupGeneratorFn: this.formGroupGeneratorFn.bind(this),
                    columns: [
                        this.columns.articleTitle,
                        new NumberColumnModel({
                            propertyName: 'stock',
                            decimalPlaces: 0,
                            columnTitleKey: 'Orders.Edit.Articles.Stock',
                            columnTitleSuffix: order.store.name
                        }),
                        new ButtonColumnModel({
                            propertyName: 'stats-icon',
                            icon: () => 'query_stats',
                            // TODO enable once the button does something...
                            isButtonDisabled: () => true,
                            onClickFunction: () => {
                            },
                            columnTitleKey: ''
                        }),
                        this.columns.orderQuantity(true),
                        this.columns.x,
                        this.columns.buyingPrice,
                        this.columns.equals,
                        this.columns.total,
                        this.columns.editDetails(order.status)
                    ],
                    showPaging: true,
                    pageSize: 10,
                    shouldHideAddButton: true,
                    additionalButtons: [
                        new EditableTableButtonModel({
                            labelKey: 'Orders.Edit.Articles.FillToTarget',
                            action: () => {
                                const dialogRef = this.matDialog.open(GeneralPromptDialogComponent, {
                                    width: '400px',
                                    maxHeight: '98svh',
                                    maxWidth: '98vw',
                                    data: {
                                        promptKey: 'Orders.Edit.Articles.FillToTargetPromptTitle',
                                        messageKey: 'Orders.Edit.Articles.FillToTargetPromptMessage'
                                    } satisfies GeneralPromptDialogDataModel
                                });

                                return dialogRef.afterClosed().pipe(
                                    filter(result => result === true),
                                    switchMap(() => this.ordersStore.calculateArticlesForOrder(order.id, true)),
                                    map(result => result?.value ?? []),
                                    tap(orderArticles => {
                                        formGroup.controls.orderArticles.setValue(orderArticles);
                                        formGroup.controls.orderArticles.markAsDirty();
                                    })
                                );
                            }
                        }),
                        new EditableTableButtonModel({
                            labelKey: 'Orders.Edit.Articles.Calculate',
                            action: () => {
                                const dialogRef = this.matDialog.open(GeneralPromptDialogComponent, {
                                    width: '400px',
                                    maxHeight: '98svh',
                                    maxWidth: '98vw',
                                    data: {
                                        promptKey: 'Orders.Edit.Articles.CalculatePromptTitle',
                                        messageKey: 'Orders.Edit.Articles.CalculatePromptMessage'
                                    } satisfies GeneralPromptDialogDataModel
                                });

                                return dialogRef.afterClosed().pipe(
                                    filter(result => result === true),
                                    switchMap(() => this.calculateArticles(order.id, formGroup))
                                );
                            }
                        }),
                        new EditableTableButtonModel({
                            labelKey: 'Orders.Edit.Articles.AddArticles',
                            action: current => this.openDataSelectionDialog(order, formGroup, current)
                        })
                    ]
                });
            case OrderStatusEnum.Sent:
            case OrderStatusEnum.Delayed:
                return new EditableTableConfigModel<OrderArticleModel, string>({
                    formGroupGeneratorFn: this.formGroupGeneratorFn.bind(this),
                    columns: [
                        this.columns.articleTitle,
                        this.columns.orderQuantity(false),
                        this.columns.x,
                        this.columns.buyingPrice,
                        this.columns.equals,
                        this.columns.total,
                        this.columns.deliveredQuantity(true),
                        this.columns.deliveredStatus,
                        this.columns.editDetails(order.status)
                    ],
                    showPaging: true,
                    pageSize: 10,
                    shouldHideAddButton: true,
                    allowDelete: false
                });
            case OrderStatusEnum.Delivered:
                return new EditableTableConfigModel<OrderArticleModel, string>({
                    formGroupGeneratorFn: this.formGroupGeneratorFn.bind(this),
                    columns: [
                        this.columns.articleTitle,
                        this.columns.orderQuantity(false),
                        this.columns.deliveredQuantity(false),
                        this.columns.deliveredStatus,
                        this.columns.x,
                        this.columns.buyingPrice,
                        this.columns.equals,
                        this.columns.totalDelivered
                    ],
                    showPaging: true,
                    pageSize: 10,
                    shouldHideAddButton: true,
                    allowDelete: false
                });
        }
    }

    articleSuppliersToOrderArticles(order: OrderModel, articleSuppliers: ArticleSupplierListModel[]): Observable<OrderArticleModel[]> {
        return forkJoin(
            articleSuppliers.map(a =>
                forkJoin({
                    order: of(order),
                    articleSupplier: of(a),
                    article: this.articlesStore.get(a.articleId),
                    articleStock: this.articleStocksStore.getStock(`${a.articleId}-${order.store.id}`)
                })
            )
        ).pipe(
            map(results => results
                .filter(result => !!result.article?.value)
                .map(result => ({
                    order: result.order,
                    articleSupplier: result.articleSupplier,
                    article: result.article.value!,
                    articleStock: result.articleStock?.value ?? 0
                }))
                .map(result => ({
                    orderId: result.order.id,
                    articleId: result.article.id,
                    articleArticleNumber: result.article.articleNumber,
                    articleTitle: result.article.title,
                    articleUnitTitle: result.article.unitShortTitle,
                    orderNumber: result.articleSupplier.orderNumber,
                    orderUnitQuantity: result.articleSupplier.unitQuantity,
                    orderQuantity: result.articleSupplier.minOrderQuantity,
                    orderBuyingPrice: result.articleSupplier.buyingPrice,
                    orderBuyingPriceExclusive: result.articleSupplier.buyingPriceExclusive,
                    stock: result.articleStock,
                    minOrderQuantity: result.articleSupplier.minOrderQuantity
                } as OrderArticleModel))
            )
        );
    }

    calculateArticles(
        orderId: number,
        formGroup: FormGroup<{ orderArticles: ArcFormControlInterface<OrderArticleModel[]> }>
    ): Observable<OrderArticleModel[]> {
        return this.ordersStore.calculateArticlesForOrder(orderId, false).pipe(
            map(result => result?.value ?? []),
            tap(orderArticles => {
                formGroup.controls.orderArticles.setValue(orderArticles);
                formGroup.controls.orderArticles.markAsDirty();
            })
        );
    }

    private formGroupGeneratorFn(): FormGroup {
        /* eslint-disable no-null/no-null */
        const validateMinOrderQuantity = (control: AbstractControl): ValidationErrors | null => {
            if (control.value === undefined || control.value === null || control.value === '' || Number.isNaN(Number(control.value))) {
                // eslint-disable-next-line no-null/no-null
                return null;
            }

            const formGroup = control.parent as FormGroup | null;
            const minOrderQuantity = formGroup?.get('minOrderQuantity')?.value ?? 0;
            return control.value < minOrderQuantity
                ? { customError: { key: 'Orders.Edit.Articles.ErrorMinOrderQuantity', params: { minOrderQuantity } } }
                : null;
        };
        /* eslint-enable no-null/no-null */

        return new FormGroup({
            id: new ArcFormControl(''),
            orderId: new ArcFormControl(''),
            articleId: new ArcFormControl(''),
            articleTitle: new ArcFormControl(''),
            articleArticleNumber: new ArcFormControl(''),
            articleUnitTitle: new ArcFormControl(''),
            orderQuantity: new ArcFormControl<OptionalType<number>>(undefined, [
                Validators.required,
                CustomValidators.number(),
                validateMinOrderQuantity
            ]),
            minOrderQuantity: new ArcFormControl(0),
            unit: new ArcFormControl(0),
            orderNumber: new ArcFormControl('', Validators.required),
            orderBuyingPrice: new ArcFormControl(0, [Validators.required, CustomValidators.number()]),
            orderBuyingPriceExclusive: new ArcFormControl(false),
            orderUnitQuantity: new ArcFormControl(0, [Validators.required, CustomValidators.number({ min: 0 })]),
            notes: new ArcFormControl(''),
            stock: new ArcFormControl(0),
            deliveredQuantity: new ArcFormControl(0, [Validators.required, CustomValidators.number({ min: 0 })]),
            deliveredUnitQuantity: new ArcFormControl(0, [Validators.required, CustomValidators.number({ min: 0 })])
        });
    }

    private openDataSelectionDialog(
        order: OrderModel,
        formGroup: FormGroup<{
            orderArticles: ArcFormControlInterface<OrderArticleModel[]>;
        }>,
        currentData: OrderArticleModel[]
    ): Observable<any> {
        const filteredStore = new SupplierArticlesStore(this.requestService, order.supplier.id);
        const dialogRef = this.matDialog.open(DataSelectionDialogComponent, {
            data: new DataSelectionDialogCustomDataModel({
                dialogTitleKey: 'ArticleSuppliers.TitleSelectDialog',
                store: filteredStore,
                columnConfig: articleSupplierSelectionTableConfig,
                isMultiSelect: true
            }),
            width: '800px',
            maxWidth: '98vw',
            height: '800px',
            maxHeight: '98svh'
        });

        return dialogRef.afterClosed().pipe(
            switchMap((result?: ArticleSupplierListModel[]) => {
                // eslint-disable-next-line eqeqeq
                result = result?.filter(a => !currentData.some(c => c.articleId == a.articleId));

                if (!result || result.length === 0) {
                    return of([]);
                }

                return this.articleSuppliersToOrderArticles(order, result);
            }),
            tap(orderArticles => {
                const currentValue = formGroup.value.orderArticles ?? [];
                formGroup.controls.orderArticles.setValue([...currentValue, ...orderArticles]);
                formGroup.controls.orderArticles.markAsDirty();
            })
        );
    }
}
