<div class="grid grid-cols-10 gap-4">
    <div class="flex flex-col gap-4 col-span-4">
        <arc-general-data-select label="Reservations.Edit.BaseData.ReservationType" [generalDataType]="GeneralDataTypeEnum.ReservationTypes"
            [formControl]="formGroup.controls.reservationTypeId" [shouldAutoSelectFirstOption]="true">
        </arc-general-data-select>
        <arc-quick-search [config]="ComplexDataTypesEnum.Customer | arcQuickSearchConfig" [formControl]="formGroup.controls.personId"
            label="Reservations.Edit.BaseData.Customer" (optionSelected)="onCustomerChanged($event)">
        </arc-quick-search>
        <div class="grid grid-cols-5 gap-4">
            <arc-input class="col-span-3" labelKey="Reservations.Edit.BaseData.Reference" [control]="formGroup.controls.externalReference"
                [prefixText]="isCreate() ? '' : item().id + ' /&nbsp;'">
            </arc-input>
            <arc-general-data-select class="col-span-2" label="Reservations.Edit.BaseData.Store"
                [generalDataType]="GeneralDataTypeEnum.Stores" [formControl]="formGroup.controls.storeId"
                [requiredPermission]="PermissionsEnum.Stores" [emptyOptionLabel]="'Reservations.Edit.BaseData.AutomaticStore' | transloco">
            </arc-general-data-select>
        </div>
        <arc-textarea label="Reservations.Edit.BaseData.Remarks" [formControl]="formGroup.controls.remarks" />
    </div>

    <div class="col-span-6 p-2 bg-app">
        <div class="grid grid-cols-3 gap-4 p-8 justify-items-stretch h-full">
            <div class="flex flex-col justify-between">
                <div>
                    <p>{{ 'Reservations.Edit.BaseData.CreatedTime' | transloco }}</p>
                    <p class="text-xl">{{ isCreate() ? '-' : (item().createdTime | arcDate : 'short') }}</p>
                </div>
                <div>
                    <p>{{ 'Reservations.Edit.BaseData.NumberOfArticles' | transloco }}</p>
                    <p class="text-xl">{{ numberOfPositionsSignal() }}</p>
                </div>
                <div>
                    <p>{{ 'Reservations.Edit.BaseData.Price' | transloco }}</p>
                    <p class="text-xl">{{ totalPriceSignal() | arcCurrency }}</p>
                </div>
            </div>
            <div class="flex flex-col justify-between">
                <div>
                    <p>{{ 'Reservations.Edit.BaseData.Booked' | transloco }}</p>
                    <p class="text-xl">
                        {{ !!item().transactionsDetail.completedTime ? (item().transactionsDetail.completedTime | arcDate:'short') : '-' }}
                    </p>
                </div>
                <div>
                    <p>{{ 'Reservations.Edit.BaseData.ArticleQuantity' | transloco }}</p>
                    <p class="text-xl">{{ numberOfArticlesSignal() }}</p>
                </div>
                <div>
                    <p>{{ 'Reservations.Edit.BaseData.Discount' | transloco }}</p>
                    <p class="text-xl">{{ totalDiscountSignal() | arcCurrency }}</p>
                </div>
            </div>
            <div class="flex flex-col justify-end">
                <div>
                    <p>{{ 'Reservations.Edit.BaseData.Total' | transloco }}</p>
                    <p class="text-xl">{{ totalSignal() | arcCurrency:undefined:true }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
