@if (entityModel().type.isDiscountVoucher) {
    <div class="w-full overflow-auto grid grid-cols-12 gap-4">
        <!-- first column -->
        <div class="flex flex-col gap-4 col-span-8">
            <div>
                <p class="text-on-app-light text-sm leading-tight">{{ 'Vouchers.Detail.VoucherType.ComponentTitle' | transloco }}</p>
                <p class="font-semibold">{{ entityModel().type.title }}</p>
            </div>
            <div>
                <div class="flex gap-8">
                    <div class="mb-4">
                        <p class="text-on-app-light text-xs leading-tight">{{ 'Vouchers.Detail.VoucherType.ValidFrom' | transloco }}</p>
                        <p [class.text-error]="entityModel().type.validFrom > today">
                            {{ entityModel().type.validFrom | arcDate }}
                        </p>
                    </div>
                    <div>
                        <p class="text-on-app-light text-xs leading-tight">{{ 'Vouchers.Detail.VoucherType.ValidUntil' | transloco }}</p>
                        <p [class.text-error]="entityModel().type.validThru < today">
                            {{ entityModel().type.validThru | arcDate }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- second column -->
        <div class="flex flex-col gap-4 col-span-4">
            <div>
                <p class="text-on-app-light text-sm leading-tight">{{ 'Vouchers.Detail.VoucherType.Discount' | transloco }}</p>
                <p class="font-semibold">
                    @if (isDiscountNumber()) {
                        {{ entityModel().type.discount | arcCurrency }}
                    } @else {
                        {{ entityModel().type.discount }}
                    }
                </p>
            </div>
        </div>
    </div>
    <div class="w-full overflow-auto grid grid-cols-12 gap-4">
        <!-- first column -->
        <div class="flex flex-col gap-4 col-span-4">
            <div>
                <p class="text-on-app-light text-xs leading-tight">{{ 'Vouchers.Detail.VoucherType.ValidForBranch' | transloco }}</p>
                <p>{{ entityModel().type.validForBranch || ('General.All' | transloco) }}</p>
            </div>
        </div>
        <!-- second column -->
        <div class="flex flex-col gap-4 col-span-4">
            <div>
                <p class="text-on-app-light text-xs leading-tight">{{ 'Vouchers.Detail.VoucherType.ValidForTags' | transloco }}</p>
                <p>{{ voucherTypeTags() || ('General.All' | transloco) }}</p>
            </div>
        </div>
        <!-- third column -->
        <div class="flex flex-col gap-4 col-span-4">
            <div>
                <p class="text-on-app-light text-xs leading-tight">{{ 'Vouchers.Detail.VoucherType.ValidForGroup' | transloco }}</p>
                <p>{{ entityModel().type.validForArticleGroup || ('General.All' | transloco) }}</p>
            </div>
        </div>
    </div>
    <div class="w-full overflow-auto grid grid-cols-12 gap-4 mt-2">
        <!-- first column -->
        <div class="flex flex-col gap-4 col-span-4">
            <div>
                <p class="text-on-app-light text-xs leading-tight">{{ 'Vouchers.Detail.VoucherType.ValidFromValue' | transloco }}</p>
                <p>
                    @if (isValidFromAmountNumber()) {
                        {{ entityModel().type.validFromAmount | arcCurrency }}
                    } @else {
                        {{ 'General.All' | transloco }}
                    }
                </p>
            </div>
        </div>
        <!-- second column -->
        <div class="flex flex-col gap-4 col-span-4">
            <div>
                <p class="text-on-app-light text-xs leading-tight">{{ 'Vouchers.Detail.VoucherType.ValidForArticleMinPrice' | transloco }}
                </p>
                <p>
                    @if (isValidForArticleMinPriceNumber()) {
                        {{ entityModel().type.validForArticleMinPrice | arcCurrency }}
                    } @else {
                        {{ 0 | arcCurrency }}
                    }
                </p>
            </div>
        </div>
        <!-- third column -->
        <div class="flex flex-col gap-4 col-span-4">
            <div>
                @if (!!entityModel().type.bonusFactor) {
                    <p class="text-on-app-light text-xs leading-tight">{{ 'Vouchers.Detail.VoucherType.BonusFactor' | transloco }}</p>
                    @if (bonusProgramPermission !== true) {
                        <div class="flex align-start">
                            <arc-no-permission-lock [missingPermission]="bonusProgramPermission"></arc-no-permission-lock>
                        </div>
                    } @else {
                        <p>{{ entityModel().type.bonusFactor | arcNumber : 2}}</p>
                    }
                }
            </div>
        </div>
    </div>
    <div class="w-full overflow-auto grid grid-cols-12 gap-2 mt-2">
        @if (entityModel().type.cumulateVouchers) {
            <div class="flex justify-center col-span-3 bg-power-search p-1 rounded items-center">
                <p class="leading-snug break-words break-text text-center text-xs text-on-power-search" [attr.lang]="currentLangCode">
                    {{ 'Vouchers.Detail.VoucherType.VoucherCanBeAccumulated' | transloco }}
                </p>
            </div>
        }
        @if (entityModel().type.cumulateDiscounts) {
            <div class="flex justify-center col-span-3 bg-power-search p-1 rounded items-center">
                <p class="leading-snug break-words break-text text-center text-xs text-on-power-search" [attr.lang]="currentLangCode">
                    {{ 'Vouchers.Detail.VoucherType.DiscountCanBeAccumulated' | transloco }}
                </p>
            </div>
        }
        @if (entityModel().type.isReusable) {
            <div class="flex justify-center col-span-3 bg-power-search p-1 rounded items-center">
                <p class="leading-snug break-words break-text hyphens-auto text-center text-xs text-on-power-search"
                    [attr.lang]="currentLangCode">
                    {{ 'Vouchers.Detail.VoucherType.IsReusable' | transloco }}
                </p>
            </div>
        }
    </div>
} @else {
    <div class="w-full grid grid-cols-12 gap-4">
        <!-- first column -->
        <div class="flex flex-col gap-4 col-span-8">
            <div>
                <p class="text-on-app-light text-sm">{{ 'Vouchers.Detail.VoucherType.ComponentTitle' | transloco }}</p>
                <p class="text-xl font-semibold">{{ entityModel().type.title }}</p>
            </div>
        </div>
    </div>
    <div class="w-full overflow-auto grid grid-cols-12 gap-4 mt-2">
        <!-- first column -->
        <div class="flex flex-col gap-4 col-span-4">
            <div>
                <p class="text-on-app-light text-xs leading-tight">{{ 'Vouchers.Detail.VoucherType.PaymentType' | transloco }}</p>
                @if (!!entityModel().type.paymentTypeDesc) {
                    <p>{{ entityModel().type.paymentTypeDesc }}</p>
                }
            </div>
        </div>
        <!-- second column -->
        <div class="flex flex-col gap-4 col-span-4">
            <div>
                <p class="text-on-app-light text-xs leading-tight">{{ 'Vouchers.Detail.VoucherType.MinimumValue' | transloco }}</p>
                @if (!!entityModel().type.minimalVoucherValue) {
                    <p>{{ entityModel().type.minimalVoucherValue | arcCurrency }}</p>
                }
            </div>
        </div>
    </div>
}
