<form [formGroup]="formGroup" class="flex flex-col gap-4">
    <arc-translation-input translationDialogTitleKey="General.Actions.TranslateTitle" labelKey="Extensions.Edit.BaseData.TitleField"
        [controlDe]="formGroup.controls.title_de" [controlEn]="formGroup.controls.title_en" [controlFr]="formGroup.controls.title_fr"
        [controlIt]="formGroup.controls.title_it"></arc-translation-input>

    <arc-translation-input translationDialogTitleKey="General.Actions.TranslateDescription" labelKey="Extensions.Edit.BaseData.Description"
        [controlDe]="formGroup.controls.description_de" [controlEn]="formGroup.controls.description_en"
        [controlFr]="formGroup.controls.description_fr" [controlIt]="formGroup.controls.description_it"></arc-translation-input>
        
    <arc-checkbox labelKey="Extensions.Edit.BaseData.IsActive" [control]="formGroup.controls.isActive"></arc-checkbox>

    @if(item().hasBackgroundTriggerBinding){
        <arc-date-time-picker labelKey="Extensions.Edit.BaseData.NextExecutionTime"
            [control]="formGroup.controls.nextExecutionTime"></arc-date-time-picker>
    }

    @if(item().isPosExtension){
        <arc-input labelKey="Extensions.Edit.BaseData.PosExtensionFileName" [control]="formGroup.controls.posExtensionFileName"></arc-input>
        <arc-file-upload [arcErrorTooltip]="formGroup.controls.posExtensionDll" [formControl]="formGroup.controls.fileControl"
            fileTypes=".dll">
        </arc-file-upload>
    }
</form>
