import { Injectable, inject } from '@angular/core';

import { SecurityStorage } from './storages/security.storage';
import { TranslationService } from './translation.service';
import { EnvironmentService } from './environment.service';
import { WelcomeBarModel } from '../models/welcome-bar-model';
import { OptionalType } from '../models/types/optional.type';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class WelcomeBarService {
    private readonly securityStorage = inject(SecurityStorage);
    private readonly translationService = inject(TranslationService);
    private readonly environmentService = inject(EnvironmentService);
    private readonly userService = inject(UserService);

    async getWelcomeBarModel(): Promise<OptionalType<WelcomeBarModel>> {
        const userInfo = await this.userService.getUserInfoAsync()!;
        const welcomeBarText = await this.translationService.getTextAsync('Home.Welcome', { userFullName: userInfo?.realName });
        const welcomeBar: WelcomeBarModel = new WelcomeBarModel({
            userFullName: userInfo?.realName,
            licenseName: userInfo?.licenseName,
            welcomeBarText
        });
        return welcomeBar;
    }

    getCompanyLogoUrl(): string {
        const token = this.securityStorage.getToken();
        const baseUrl = this.environmentService.baseUrl;

        return `${baseUrl}/general-data/logo?token=${token}`;
    }
}
