export enum ReservationStatusEnum {
    Open = 0,
    Completed = 1
}

export class ReservationStatusEnumExtensions {
    static getColor(value: ReservationStatusEnum): string {
        switch (value) {
            case ReservationStatusEnum.Open:
                return 'bg-element-selected text-on-app';
            case ReservationStatusEnum.Completed:
                return 'bg-success-light text-on-success-light';
        }
    }
}
