<div class="grid items-center grid-cols-12 gap-4">

    <form [formGroup]="formGroup" clasS="col-span-5 smd:col-span-full flex flex-col gap-4">

        <arc-datepicker [control]="formGroup.controls.expectedDeliveryDate" labelKey="Orders.Edit.BaseData.ExpectedDeliveryDate"
            class="w-2/3 slg:w-full">
        </arc-datepicker>

        <arc-input [control]="formGroup.controls.discount" labelKey="Orders.Edit.BaseData.Discount" class="w-2/3 slg:w-full">
            <mat-icon suffix [matTooltip]="'Orders.Edit.BaseData.DiscountInfoText' | transloco">info_outline</mat-icon>
        </arc-input>

        <arc-textarea [formControl]="formGroup.controls.remarks" label="Orders.Edit.BaseData.Remarks" class="w-full">
        </arc-textarea>

    </form>

    <div class="col-span-7 smd:col-span-full h-full" [ngClass]="statusBgColorClass">
        <div class="px-8 py-4 flex gap-16">

            <div class="flex flex-col gap-x-16 gap-y-4 min-w-fit">
                <div>
                    <p class="text-sm">{{ 'Orders.Edit.BaseData.DateSent' | transloco }}</p>
                    <p class="text-xl">{{ !!item().dateSent ? (item().dateSent | arcDate) : '-' }}</p>
                </div>
                <div>
                    <p class="text-sm">{{ 'Orders.Edit.BaseData.DateDelivered' | transloco }}</p>
                    <p class="text-xl">{{ !!item().dateDelivered ? (item().dateDelivered | arcDate) : '-' }}</p>
                </div>
            </div>

            <div class="flex flex-col gap-x-16 gap-y-4">
                <div>
                    <p class="text-sm">{{ 'Orders.Edit.BaseData.OrderValue' | transloco }}</p>
                    <p class="text-xl">{{ (orderValue() | arcCurrency:undefined:true) ?? '-' }}</p>
                </div>
                <div>
                    <p class="text-sm">{{ 'Orders.Edit.BaseData.OrderedArticlesCount' | transloco }}</p>
                    <p class="text-xl">{{ (orderedArticlesCount() | arcNumber) ?? '-' }}</p>
                </div>
                <div>
                    <p class="text-sm">{{ 'Orders.Edit.BaseData.DeliveredArticlesCount' | transloco }}</p>
                    @if (item().status === OrderStatusEnum.Draft) {
                        <p class="text-xl">-</p>
                    } @else {
                        <p class="text-xl">{{ (deliveredArticlesCount() | arcNumber) ?? '-' }}</p>
                    }
                </div>
            </div>

            <div>
                <p class="text-sm">{{ 'Orders.Edit.BaseData.Supplier' | transloco }}</p>
                <p class="text-xl min-h-[1em]">{{ item().supplier.displayName }}</p>
                <p class="text-xl min-h-[1em]">{{ item().supplier.street }} {{ item().supplier.streetNumber }}</p>
                <p class="text-xl min-h-[1em]">{{ item().supplier.zip }} {{ item().supplier.city }}</p>
                <p class="text-xl min-h-[1em]"></p>
                <p class="text-xl">{{ item().supplier.contactPhone }}</p>
                <p class="text-xl min-h-[1em]">{{ item().supplier.contactMobile }}</p>
            </div>

        </div>

    </div>

</div>
