import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { map, filter } from 'rxjs';

import { BaseComponent } from '../components/abstractions/base.component';
import { LayoutService } from '../core/services/layout.service';
import { SettingsStorage } from '../core/services/storages/settings.storage';
import { ImportantMessagesDialogComponent } from '../components/dialogs/important-messages-dialog/important-messages-dialog.component';
import { MessagesStore } from './services/stores/messages-store';
import { TranslationService } from '../core/services/translation.service';
import { AuthService } from '../core/services/auth.service';
import { UserService } from '../core/services/user.service';
import { HelpService } from '../core/services/help.service';

@Component({
    selector: 'arc-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit {
    @HostBinding('class.dark') isDarkMode = false;

    private readonly settingsStorage = inject(SettingsStorage);
    private readonly layoutService = inject(LayoutService);
    private readonly matIconRegistry = inject(MatIconRegistry);
    private readonly domSanitizer = inject(DomSanitizer);
    private readonly matDialog = inject(MatDialog);
    private readonly authService = inject(AuthService);
    private readonly userService = inject(UserService);
    private readonly messagesStore = inject(MessagesStore);
    private readonly translationService = inject(TranslationService);
    private readonly helpService = inject(HelpService);
    private readonly titleService = inject(Title);
    private readonly router = inject(Router);
    private readonly activatedRoute = inject(ActivatedRoute);

    constructor() {
        super();

        this.userService.initUserData();
        this.matIconRegistry.addSvgIcon(
            'arcavis_icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/arcavis_icon.svg')
        );
        this.activatedRoute.queryParamMap.subscribe(params => {
            const ticketId = params.get('openTicketId');

            if (!!ticketId) {
                this.helpService.currentlyOpenedTicketId = +ticketId;
                this.helpService.openTickets();
            }
        });
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => {
                    let child = this.activatedRoute.firstChild;

                    while (!!child) {
                        if (!!child.firstChild) {
                            child = child.firstChild;
                        } else if (!!child.snapshot.data && !!child.snapshot.data['title']) {
                            return `Layout.Menu.${child.snapshot.data['title']}`;
                        } else {
                            return undefined;
                        }
                    }

                    return undefined;
                })
            )
            .subscribe((data?: any) => {
                if (!!data) {
                    this.translationService.getTextAsync(data).then(txt => this.titleService.setTitle(`Arcavis | ${txt}`));
                } else {
                    this.titleService.setTitle('Arcavis');
                }
            });
    }

    ngOnInit(): void {
        this.isDarkMode = this.settingsStorage.getDarkMode() ?? false;

        this.layoutService.setDarkMode(this.isDarkMode);

        const darkModeChangedSubscription = this.layoutService.getThemeChangedObservable().subscribe(isDarkMode => {
            this.isDarkMode = isDarkMode;
            this.settingsStorage.saveDarkMode(isDarkMode);
        });

        if (!!this.userService.getUserInfo()) {
            this.setupImportantMessagesCheck();
        }

        this.addSubscriptions(darkModeChangedSubscription);
    }

    private setupImportantMessagesCheck(): void {
        const lastLoginTimeStamp = this.settingsStorage.getLastLoginTimeStamp();
        const lastCheckedImportantMessagesTimeStamp = this.settingsStorage.getLastCheckedImportantMessagesTimeStamp();
        const checkLoginSubscription = this.authService.isLoggedIn().subscribe(isLoggedIn => {
            if (
                (isLoggedIn && !!lastLoginTimeStamp && !lastCheckedImportantMessagesTimeStamp) ||
                lastLoginTimeStamp! > lastCheckedImportantMessagesTimeStamp!
            ) {
                const messagesSubscription = this.messagesStore.getNewHighPriorityMessages().subscribe(response => {
                    if (!!response.value && response.value?.length > 0) {
                        const messages = response.value;
                        const dialogRef = this.matDialog.open(ImportantMessagesDialogComponent, {
                            data: messages,
                            width: '800px',
                            maxHeight: '90svh',
                            disableClose: true
                        });

                        dialogRef.afterClosed().subscribe(() => {
                            this.settingsStorage.saveCheckedImportantMessagesTimeStamp(Date.now());
                        });
                    }
                });

                this.addSubscriptions(messagesSubscription);
            }
        });

        this.addSubscriptions(checkLoginSubscription);
    }
}
