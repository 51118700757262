@if(isLoading()) {
    <div class="w-full h-full grid place-items-center">
        <mat-spinner [diameter]="32" />
    </div>
} @else {
    @if(!isCreatingTicket() && !selectedArticle()) {
        <div class="mb-10">
            <input class="w-full h-16 bg-element border-solid border border-on-app-light rounded-md p-5" type="text"
                [matAutocomplete]="searchAutocomplete" (input)="onSearch($event)" [placeholder]="'Help.SearchPlaceholder' | transloco" />
            <mat-autocomplete #searchAutocomplete="matAutocomplete" class="!p-0" hideSingleSelectionIndicator="true"
                (optionSelected)="onSearchResultSelected($event)">
                <mat-option *ngFor="let item of searchResults()" [value]="item" class="!p-0">
                    <div class="flex flex-row p-3 group hover:bg-element-hover hover:cursor-pointer">
                        <div>
                            <arc-icon [icon]="item.helpSubTopicIcon || item.helpTopicIcon || 'help'" [size]="40" class="flex-none" />
                        </div>
                        <div class="flex-1 flex flex-col">
                            <div class="font-bold">
                                {{ item.title }}
                            </div>
                            <div>
                                {{ item.summary }}
                            </div>
                        </div>
                    </div>
                </mat-option>
            </mat-autocomplete>
        </div>
    }
    @if (isHelpArticleView() || !!selectedArticle()) {
        <arc-selected-article [selectedArticle]="selectedArticle()!" />
    }
    @else if (isCreatingTicket()) {
        <arc-new-ticket />
    }
    @else {
        @if (recommendedArticles().length > 0) {
            <h2 class="text-accent font-bold">{{ 'Help.RecommendedArticles' | transloco }}</h2>
            <div class="flex flex-col gap-4 mt-8 mb-10">
                @for(article of recommendedArticles(); track article.id) {
                    <arc-article-list-item [article]="article" (articleSelected)="selectArticle($event)" />
                }
            </div>
        }
        @if (otherArticles().length > 0) {
            <h2 class="text-accent font-bold">{{'Help.MoreArticles' | transloco}}</h2>
            <div class="flex flex-col gap-4 mt-8 mb-10">
                @for(article of otherArticles(); track article.id) {
                    <arc-article-list-item [article]="article" (articleSelected)="selectArticle($event)" />
                }
            </div>
        }
        <div class="flex gap-4 items-center justify-end">
            <arc-button type="flat" color="accent" (clicked)="createTicket()">
                {{ 'Tickets.CreateTicket' | transloco }}
            </arc-button>
        </div>
    }
}
